import React, { useState, useEffect } from 'react';
import './AI.css'; 


import testimonialImage1 from '../assets/test1.png';
import testimonialImage2 from '../assets/test2.png';
import testimonialImage3 from '../assets/test3.png';
import testimonialImage4 from '../assets/test4.png';
import { color, motion } from 'framer-motion';
// import '../styles/HowItWorks.css';
import hiw_bg from '../assets/hiw_bg.png';
import hiw_1 from '../assets/hiw_1.png';
import hiw_2 from '../assets/hiw_2.png';
import hiw_3 from '../assets/hiw_3.png';
import st1 from '../assets/st1.png';
import st2 from '../assets/st2.png';
import st3 from '../assets/st3.png';
import st4 from '../assets/st4.png';

const BannerTwo = () => (
  <section className="banner-two">
    <div className="container">
      <div className="banner-grid">
        <div className="banner-two__left-content">
          <h2>
            EFFICENT TEAMS <br /> WITH KOLIN AI
          </h2>
          <p style={{ color: 'white' }}>
          See how our AI-powered solution transforms task management with ease and efficiency. In this interactive demo, you can simulate the task assignment process that Kolin AI handles behind the scenes— automatically distributing tasks based on team members' skills, availability, and workload.
          </p>
          
          <div className="banner-two__left-content-text1">
            <ul>
              {[hiw_2, hiw_1, hiw_3].map((img, index) => (
                <li key={index}>
                  <div className="img-box">
                    <img src={img} alt="Team Member" />
                  </div>
                </li>
              ))}
            </ul>
            {/* <div className="counter-box">
              <h1>600+ Satisfied Customers</h1>
            </div> */}
          </div>
          <a href="/#contact" className="button-style-1">
           Buy Kolin AI Now ($19.99)
          </a>
        </div>
        <div>
        <DemoSection />
        </div>
      </div>
    </div>
  </section>
);

const ServicesTwo = () => {
  const services = [
    {
      img: st1,
      title: "Automated Task Assignment",
      description: "Intelligent task distribution based on team skills and availability.",
    },
    {
      img: st2,
      title: "Real-Time Task Prioritization",
      description: "Dynamic task adjustments to keep projects on track.",
    },
    {
      img: st3,
      title: "Overdue Task Alerts",
      description: "Instant notifications to prevent missed deadlines.",
    },
    {
      img: st4,
      title: "Team Insights & Analytics",
      description: "In-depth performance tracking and workload analysis.",
    },
  ];

  return (
    <section className="services-two">
      <div className="container">
        <h2 style={{ color:"#000",textAlign: "center", marginBottom: "40px" }}>Why SmartTasker?</h2>
        <div className="services-grid">
          {services.map((service, index) => (
            <div className="services-two__single" key={index}>
              <div className="services-two__single-img">
                <img src={service.img} alt={service.title} />
              </div>
              <h3 style={{color:"#fff"}}>{service.title}</h3><br/>
              <p style={{color:"#d0cece"}}>{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const DemoSection = () => (
  <section id="demo" className="demo-section">
    <div className="container">
      <div className="demo-form">
        <h1 style={{fontSize: "26px", textAlign: "center", marginBottom: "20px", color:"#000" }}>Experience SmartTasker</h1>
        <form>
          <input type="text" placeholder="Team Member Name" className="demo-input" required />
          <input type="text" placeholder="Task Name" className="demo-input" required />
          <input type="date" className="demo-input" required />
          <button type="submit" className="demo-button">Let AI Assign Tasks</button>
        </form>
      </div>
    </div>
  </section>
);

 
const Testimonial = () => {
    const testimonials = [
      {
        name: 'Lauren D',
        role: 'Project Manager at Digital Pulse Marketing',
        text: `"We’ve seen a noticeable improvement in project efficiency since integrating Kolin AI. It’s like having an extra team member who’s always a step ahead in task management.
  `,
        image: testimonialImage1,
      },
      {
        name: 'William S',
        role: 'Operations Manager at Financial Solutions Group',
        text: `"We’ve reduced our backlog by 40% thanks to Kolin AI. It assigns tasks faster than we ever could, and ensures the team is always working on the most important tasks first.`,
        image: testimonialImage2,
      },
      {
        name: 'Wimberly E',
        role: ' Business Owner',
        text: `"Kolin AI has saved us hours of manual task assignment. It ensures the right people are working on the right tasks at the right time, which has massively improved our project flow.`,
        image: testimonialImage3,
      },
      
    ];
  
    const [currentIndex, setCurrentIndex] = useState(0);
  
  
    const handlePrev = () => {
      setCurrentIndex((prevIndex) => (prevIndex === 0 ? testimonials.length - 2 : prevIndex - 1));
    };
  
    const handleNext = () => {
      setCurrentIndex((prevIndex) => (prevIndex === testimonials.length - 2 ? 0 : prevIndex + 1));
    };
  
    
    useEffect(() => {
      const interval = setInterval(() => {
        handleNext();
      }, 4000); 
  
      return () => clearInterval(interval); 
    }, []);
  
    return (
      <div style={{paddingBottom:"500px"}} className="testimonial-section" id="testimonial">
        <h2 className="testimonial-heading">What Our Users Say</h2>
        <div className="testimonial-container">
          {testimonials.slice(currentIndex, currentIndex + 2).map((testimonial, index) => (
            <div className="testimonial-card" key={index}>
              {/* Left Column for Image */}
              <div className="testimonial-image-column">
                <img
                  src={testimonial.image}
                  alt={testimonial.name}
                  className="profile-image"
                />
              </div>
  
              {/* Right Column for Text */}
              <div className="testimonial-text-column">
                <p className="testimonial-name">{testimonial.name}</p>
                <p className="testimonial-role">{testimonial.role}</p>
                <div className="testimonial-text">{testimonial.text}</div>
              </div>
            </div>
          ))}
        </div>
  
        {/* Navigation Buttons */}
        <div className="nav-buttons">
          <button onClick={handlePrev}>&larr;</button>
          <button onClick={handleNext}>&rarr;</button>
        </div>
      </div>
    );
  }; 
 
  const HowItWorks = () => {
    return (
      <div style={{background:"#000", color:"#fff"}} className="how-it-works" id="howitworks">
        {/* First container */}
        <div className="first-container">
          <div className="heading-container">
            <h2 className="sub-heading">How It Works</h2>
            <h1 className="main-heading" style={{color:"#fff"}}>
              Begin your journey to smarter customer management right today. Sign up today and see the power of automation in action!
            </h1>
          </div>
          <div className="image-container">
            <img src={hiw_bg} alt="How it works" className="hiw-image" />
          </div>
        </div>
  
        {/* Second container with 6 sections */}
        <div className="second-container">
          <div className="row">
            <div className="section section1">
              <div className="number-circle">1</div>
              <h3 className="section-heading">Input Your Team & Tasks</h3>
              <p className="section-text">
              At Kolin AI, we believe that efficient task management is the backbone of successful teams. Designed by Kolina Solutions, our AI-powered solution automates the task assignment process, ensuring that your team can focus on what truly matters—delivering high-quality results.


              </p>
            </div>
            {/* Image 1 with entrance animation */}
            <motion.div
              className="section section2"
              initial={{ opacity: 0, y: -100 }} // Increased falling height (y: -100)
              whileInView={{ opacity: 1, y: 0 }} // Falling animation
              transition={{ duration: 1.5, ease: 'easeOut' }}
              viewport={{ once: true }} // Trigger animation only once when the image comes into view
            >
              <img src={hiw_1} alt="Image 1" className="section-image" />
            </motion.div>
          </div>
  
          <div className="row">
            {/* Image 2 with entrance animation */}
            <motion.div
              className="section section2"
              initial={{ opacity: 0, y: -100 }} // Increased falling height (y: -100)
              whileInView={{ opacity: 1, y: 0 }} // Falling animation
              transition={{ duration: 1.5, ease: 'easeOut' }}
              viewport={{ once: true }} // Trigger animation only once when the image comes into view
            >
              <img src={hiw_2} alt="Image 2" className="section-image" />
            </motion.div>
            <div className="section section1">
              <div className="number-circle">2</div>
              <h3 className="section-heading">Let AI Handle the Rest</h3>
              <p className="section-text">
              Kolin AI automatically assigns tasks based on individual skills, workload, and availability, while dynamically adjusting priorities to optimize workflow in real time.
              </p>
            </div>
          </div>
  
          <div className="row">
            <div className="section section1">
              <div className="number-circle">3</div>
              <h3 className="section-heading">Track Progress with Insights</h3>
              <p className="section-text">
              Monitor team performance, track task progress, and receive real-time analytics and alerts, empowering you to make informed decisions and keep projects on track.
              </p>
            </div>
            {/* Image 3 with entrance animation */}
            <motion.div
              className="section section2"
              initial={{ opacity: 0, y: -100 }} // Increased falling height (y: -100)
              whileInView={{ opacity: 1, y: 0 }} // Falling animation
              transition={{ duration: 1.5, ease: 'easeOut' }}
              viewport={{ once: true }} // Trigger animation only once when the image comes into view
            >
              <img src={hiw_3} alt="Image 3" className="section-image" />
            </motion.div>
          </div>
        </div>
      </div>
    );
  };
  

 

const AI = () => (
  <div id='abt'>

    
    <BannerTwo />
    <ServicesTwo />
    <HowItWorks />
    <Testimonial />
   
  </div>
);


export default AI;
